import * as React from "react";
const SvgInjuries = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        strokeWidth: 1.00000644,
      }}
      transform="matrix(-1 0 0 .99999 50.662 -120.362)"
    >
      <circle
        cx={35.233}
        cy={135.792}
        r={15.429}
        style={{
          fill: "#351431",
          fillOpacity: 1,
          strokeWidth: 0.46500298,
        }}
      />
      <g
        style={{
          fill: "#fff",
          strokeWidth: 1.08803046,
        }}
        transform="translate(21.395 123.883)scale(.24318)"
      >
        <path
          d="M31.6 31.8 29.2 33c-.4.2-.4.7-.1 1l6.4 4.6c.5.4 1.2-.3.8-.8l-2.6-3.4 2.6-1.2c.3-.2.4-.6.2-.9l-4.8-5.7c-.2-.2-.4-.3-.7-.1l-2.7 1.3c-.4.2-.4.7-.1.9zm3.1 9.2-4.2-.5 1-2.8c.1-.4-.1-.7-.5-.8l-7.5-.6c-.3 0-.5.1-.6.4l-1 2.8c-.1.4.2.8.6.8l4.8-.2-.9 2.5c-.1.4.2.8.6.8l7.8-1.3c.6-.1.6-1-.1-1.1M78 51.9c-.2-1.1-2.1-10.8-3.7-14.5q-.3-.6-.6-1.5C72.2 31.7 69.4 24 62.3 24h-11c-6.6 0-9.6 6.6-11.1 10.9-.2.1-.5.2-.5.5L35 51.3c-.7 2.4.7 4.9 3 5.6.2.1.3.1.5.1.3 0 .5.1.8.1 1.9 0 3.7-1.3 4.3-3.2l3.9-13.2.3 14.3-4.3 37.2c-.3 2.6 1.6 5 4.2 5.3h.6c2.4 0 4.4-1.8 4.7-4.2l3.9-33.7 3.9 33.7c.3 2.6 2.7 4.5 5.3 4.2s4.5-2.7 4.2-5.3L65.8 55l.3-16.2c.2.6.4 1.2.7 1.7.9 2.2 2.5 9.2 3.2 13 .4 2.2 2.6 3.6 4.8 3.2 2.2-.5 3.6-2.6 3.2-4.8m-35.4-1.1-5.9 1.4v-.4l.5-1.8 6-1.5zm1.3-4.2-6 1.5.7-2.2 6-1.5zm2.6-8.9-.1.4-6 1.5.8-2.8zm-6.8 3.9 6-1.5-.7 2.2-6 1.5zm2.2 11.8c-.3 1.1-1.4 1.9-2.6 1.9h-.5c-.1 0-.2 0-.3-.1q-.9-.3-1.5-1.2l5-1.2z"
          style={{
            fill: "#fff",
            strokeWidth: 1.08803046,
          }}
        />
        <circle
          cx={56.8}
          cy={12}
          r={9.5}
          style={{
            fill: "#fff",
            strokeWidth: 1.08803046,
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgInjuries;
