import * as React from "react";
const SvgReductionInWaterQuality = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#266334",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M5.22 5.74h26.819v26.819H5.22z"
        style={{
          clipRule: "evenodd",
          fill: "none",
          fillRule: "evenodd",
          strokeWidth: 0.26458335,
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <g
        style={{
          fill: "#fff",
          strokeWidth: 18.33885956,
        }}
      >
        <path
          d="m1050.9 445.12-6-7.125c-5.812-6.937-7.875-16.312-5.25-25.125l8.25-28.5-.937-.375c6.375 1.875 13.125-1.875 15-8.25l11.812-40.5c1.874-6.375-1.876-13.125-8.25-15l-61.689-18c-6.375-1.875-13.125 1.875-15 8.25l-11.812 40.5c-1.875 6.375 1.875 13.125 8.25 15l30.75 9-31.875-9.187-8.25 28.5c-2.625 8.812-9.375 15.562-18 18.375l-8.812 2.812c-20.625 6.563-38.438 19.125-51.188 35.812l177.19 51.375c-1.875-21-10.125-41.062-24-57.75zm-69.71 126.76c11.812 4.688 19.5 7.875 38.625 7.875s26.812-3.187 38.625-7.875c1.5-.562 2.813-1.125 4.313-1.687l8.625-29.438-191.06-55.5-24.375 83.625c13.5-5.063 30.75-10.125 58.688-10.125 33.562 0 51.75 7.312 66.375 13.125zM300 411.19c0 11.062 9 20.062 20.062 20.062h34.875c11.062 0 20.062-9 20.062-20.062V151.31c0-11.062-9-20.062-20.062-20.062h-34.875c-11.062 0-20.062 9-20.062 20.062v17.438H75v225h225zm187.88 421.69-5.625-2.062 2.063-5.625c3.75-9.75-1.125-20.438-10.875-24.188-9.563-3.75-20.438 1.125-24.188 10.875l-2.063 5.625-5.625-2.063c-9.562-3.75-20.438 1.125-24.188 10.875s1.125 20.438 10.875 24.188l5.625 2.063-2.062 5.625c-3.75 9.75 1.125 20.438 10.875 24.188 2.25.75 4.5 1.312 6.75 1.312 7.5 0 14.625-4.687 17.438-12l2.062-5.625 5.625 2.063c2.25.75 4.5 1.312 6.75 1.312 7.5 0 14.625-4.687 17.438-12 3.75-9.75-1.125-20.438-10.875-24.188z"
          style={{
            fill: "#fff",
            strokeWidth: 18.33885956,
          }}
          transform="translate(3.861 2.851)scale(.01924)"
        />
        <path
          d="M1019.8 617.25c-52.5 0-52.5-21-105.19-21s-52.5 21-105 21c-30.938 0-43.688-7.312-59.812-13.312v-135.19c0-144.94-117.56-262.5-262.5-262.5h-75v168.75h56.25c62.062 0 112.5 50.438 112.5 112.5v128.81c-34.875-4.312-41.062-19.875-86.438-19.875-52.5 0-52.5 21-105 21s-52.5-21-105-21-52.5 21-105 21-52.5-21-104.81-21v528.75h1050v-528.75c-52.5 0-52.5 21-105.19 21zM647.8 600v-56.25c0-10.312 8.438-18.75 18.75-18.75 10.313 0 18.75 8.438 18.75 18.75v53.625c-15.562 2.063-25.5 6.188-35.625 10.312-1.125-2.437-1.875-5.062-1.875-7.687M479.05 300c-10.312 0-18.75-8.437-18.75-18.75 0-10.312 8.438-18.75 18.75-18.75 113.81 0 206.25 92.438 206.25 206.25 0 10.312-8.437 18.75-18.75 18.75-10.312 0-18.75-8.437-18.75-18.75 0-93-75.75-168.75-168.75-168.75M224.8 684c20.625 0 37.5 16.875 37.5 37.5S245.425 759 224.8 759s-37.5-16.875-37.5-37.5 16.875-37.5 37.5-37.5m-18.375 377.44-67.875-151.5 103.31 44.625zm35.625-106.88s21.375-96.75 84.375-148.5l82.5 184.69c-80.812 12.562-167.06-36-167.06-36zm203.81 25.5s0 .188-.375.188l-87-194.44s.188-.188.375-.188c107.06-47.812 180.56 5.437 199.12 21.188 3 2.625 4.688 6.562 4.688 10.5-.563 24.375-9.75 114.75-116.81 162.75zm172.69 59.438c-20.625 0-37.5-16.875-37.5-37.5s16.875-37.5 37.5-37.5 37.5 16.875 37.5 37.5-16.875 37.5-37.5 37.5m358.88-58.312-168.56 72.188c-15 6.375-32.25-.562-38.625-15.375l-117.38-273.75c-1.125-2.625 0-5.812 2.812-6.937l46.688-20.062c2.625-1.125 5.813 0 6.938 2.812l49.312 115.12c11.438.938 35.25 1.5 61.875-10.312 25.5-11.25 40.875-27.938 48-36.938l-51.375-120 51.562-22.125c2.625-1.125 5.812 0 6.937 2.813l117.38 273.75c6.375 15-.562 32.25-15.375 38.625zm41.438-205.69c-20.625 0-37.5-16.875-37.5-37.5s16.875-37.5 37.5-37.5 37.5 16.875 37.5 37.5-16.875 37.5-37.5 37.5"
          style={{
            fill: "#fff",
            strokeWidth: 18.33885956,
          }}
          transform="translate(3.861 2.851)scale(.01924)"
        />
      </g>
    </g>
  </svg>
);
export default SvgReductionInWaterQuality;
