import * as React from "react";
const SvgDamageOrLossOfCoastalDefences = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#266334",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M5.22 5.74h26.819v26.819H5.22z"
        style={{
          clipRule: "evenodd",
          fill: "none",
          fillRule: "evenodd",
          strokeWidth: 0.26458335,
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <g
        style={{
          fill: "#fff",
          strokeWidth: 18.944561,
        }}
      >
        <path
          d="M300 150c0-30.938-25.312-56.25-56.25-56.25h-37.5v37.5h37.5c10.312 0 18.75 8.438 18.75 18.75 0 10.313-8.437 18.75-18.75 18.75H37.5v37.5h206.25c30.938 0 56.25-25.312 56.25-56.25M150 281.25h243.75c10.312 0 18.75 8.438 18.75 18.75 0 10.313-8.437 18.75-18.75 18.75h-37.5v37.5h37.5c31.172 0 56.25-25.078 56.25-56.25s-25.078-56.25-56.25-56.25H150ZM243.75 525h37.5v37.5h-37.5zm-56.25-75H225v37.5h-37.5zm18.75 168.75h37.5v37.5h-37.5zM300 412.5h37.5V450H300Zm120.7 588.7 70.547-13.125 12.891-77.578-50.391-25.312-56.719 56.719zM223.83 1038l-26.484-26.719-122.34-20.391v74.062zm-36.33-66.28v-38.438l-46.172-11.484-19.453 38.906zm50.39 27.19 30.938 30.938 31.172-5.86v-22.5l-22.5-22.5z"
          style={{
            fill: "#fff",
            strokeWidth: 18.944561,
          }}
          transform="translate(4.435 3.82)scale(.01862)"
        />
        <path
          d="M225 904.22v59.297l60-30 52.5 52.5v30.938l45.703-8.672-30.234-75.234 93.281-93.28 99.375 49.687-15.234 91.172 20.625-3.984 67.5-101.25v-114.61l52.266-52.266-36.328-72.656 37.5-56.25-31.172-62.344c-32.344-31.406-75.469-48.984-120.47-48.75h-11.484c-79.922.234-148.59 56.484-164.3 134.77l-.469 2.11a10.71 10.71 0 0 0 8.438 12.655c.703.234 1.406.234 2.11.234h2.343c4.453 0 8.672-2.343 10.78-6.093l35.157-58.828c10.78-17.812 30-28.828 50.859-28.828 25.547 0 48.28 16.406 56.25 40.547 40.78 75.938 12.188 170.86-63.75 211.64-11.953 6.328-24.61 11.25-37.734 14.297l-217.27 51.797-8.672-36.562 217.27-51.797c36.328-8.438 66.328-33.516 81.328-67.734 5.625-12.656 8.906-26.484 9.844-40.312-26.72 43.359-71.72 72.188-122.11 78.516l-293.91 41.484v204.14l46.172-92.344z"
          style={{
            fill: "#fff",
            strokeWidth: 18.944561,
          }}
          transform="translate(4.435 3.82)scale(.01862)"
        />
        <path
          d="m677.81 638.91 38.672 77.344-60.234 60.234v110.39l-82.5 123.75-307.73 57.656-191.02 34.922v21.797h1050v-487.5h-446.25ZM562.5 1087.5H525V1050h37.5zm150-37.5H675v-37.5h37.5zm187.5 18.75h-37.5v-37.5H900Zm150 18.75h-37.5V1050h37.5zm-18.75-300h37.5V825h-37.5zM975 693.75h37.5v37.5H975ZM975 900h37.5v37.5H975Zm-93.75-150h37.5v37.5h-37.5zM862.5 993.75H825v-37.5h37.5zM843.75 900h-37.5v-37.5h37.5zM787.5 675H825v37.5h-37.5ZM750 768.75h37.5v37.5H750Zm-18.75 150h-37.5v-37.5h37.5zM1125 525V318.75L993.75 187.5 862.5 318.75V525H750V412.03c42.656-4.453 75-40.312 75-82.969 0-6.797-.937-13.594-2.578-20.156l-16.172-65.156v-93.75c0-41.484-33.516-75-75-75s-75 33.516-75 75v93.75l-16.172 64.922c-1.64 6.562-2.578 13.359-2.578 20.156 0 42.891 32.344 78.75 75 82.969v113.2h-26.016l28.828 57.656-11.72 17.344h421.41zm-168.75 0V375h75v150z"
          style={{
            fill: "#fff",
            strokeWidth: 18.944561,
          }}
          transform="translate(4.435 3.82)scale(.01862)"
        />
      </g>
    </g>
  </svg>
);
export default SvgDamageOrLossOfCoastalDefences;
