import * as React from "react";
const SvgFoodSecurity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#266334",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M5.22 5.74h26.819v26.819H5.22z"
        style={{
          clipRule: "evenodd",
          fill: "none",
          fillRule: "evenodd",
          strokeWidth: 0.26458335,
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <path
        d="M1175 710.7H25a25 25 0 0 0-25 25 325.005 325.005 0 0 0 325 325.001h550a325.005 325.005 0 0 0 325.002-325 25 25 0 0 0-25-25zM522.21 441.28l-62.188-16.664a125.02 125.02 0 0 0-94.852 12.488 124.98 124.98 0 0 0-58.238 75.898l-4.41 16.453 62.188 16.664a124.99 124.99 0 0 0 153.09-88.386zM508.32 584.6l62.188 16.664a125.004 125.004 0 0 0 153.094-88.386l4.41-16.453-62.188-16.664v-.004a125.03 125.03 0 0 0-94.855 12.488 125 125 0 0 0-58.238 75.902zm205.79 55.15 62.188 16.664v-.004a125 125 0 0 0 94.852-12.488 124.97 124.97 0 0 0 58.238-75.898l4.41-16.453-62.188-16.664a125 125 0 0 0-94.852 12.488 124.98 124.98 0 0 0-58.238 75.898zm-235.7-283.73 62.188 16.664 4.41-16.453h-.004a124.99 124.99 0 0 0-88.386-153.093l-62.188-16.664-4.41 16.453h.004a125 125 0 0 0 12.484 94.852 125 125 0 0 0 75.902 58.242zm205.79 55.14 62.188 16.664 4.41-16.453a125.02 125.02 0 0 0-12.488-94.852 125 125 0 0 0-75.902-58.242l-62.188-16.664-4.41 16.453h.004a125 125 0 0 0 12.488 94.855 124.97 124.97 0 0 0 75.898 58.238zm205.79 55.14 62.188 16.664 4.41-16.453-.004-.004a124.99 124.99 0 0 0-88.386-153.09l-62.188-16.664-4.41 16.453h.004a125 125 0 0 0 12.484 94.852A125 125 0 0 0 889.99 466.3m258.81 106.11a35.52 35.52 0 0 0-3.547-26.945 35.5 35.5 0 0 0-21.562-16.543l-125.76-33.699-18.379 68.598 125.76 33.699a35.5 35.5 0 0 0 26.945-3.547 35.52 35.52 0 0 0 16.543-21.562zm-999.39-157.5a35.507 35.507 0 0 0-43.434 43.485 35.51 35.51 0 0 0 25.051 25.113l91.465 24.508a35.513 35.513 0 0 0 43.492-43.488 35.52 35.52 0 0 0-25.108-25.11zm55.14-205.79 91.465 24.508a35.51 35.51 0 0 0 34.254-9.223 35.51 35.51 0 0 0-15.871-59.375l-91.465-24.508a35.52 35.52 0 0 0-34.301 9.192 35.51 35.51 0 0 0 15.917 59.406zM76.328 321.8l251.52 67.395v.004a35.53 35.53 0 0 0 26.98-3.516 35.5 35.5 0 0 0 16.57-21.578 35.5 35.5 0 0 0-25.168-43.504L94.71 253.206l-.003-.004a35.515 35.515 0 0 0-43.434 43.49A35.51 35.51 0 0 0 76.328 321.8"
        style={{
          strokeWidth: 18.36344337,
          fill: "#fff",
        }}
        transform="translate(3.982 4.025)scale(.01921)"
      />
    </g>
  </svg>
);
export default SvgFoodSecurity;
