function Introduction() {
    return (
        <div className="grey-section">
            <p>Use this tool to see what the scientific research is saying about:</p>
            <ul>
                <li>
                    <strong className="text-emphasis">How</strong> local climates will change
                </li>
                <li>
                    <strong className="text-emphasis">What</strong> health and community impacts may occur as a result
                </li>
                <li>
                    <strong className="text-emphasis">Who</strong> will be most vulnerable and why
                </li>
                <li>
                    <strong className="text-emphasis">Which</strong> adaptations to consider
                </li>
            </ul>
            <p>
                LCAT is <strong className="text-emphasis">evidence-based</strong> and designed with and for{" "}
                <strong className="text-emphasis"> local decision makers.</strong> To provide feedback on the tool and
                aid us in the co-design process, please fill in our{" "}
                <a
                    href="https://forms.office.com/pages/responsepage.aspx?id=d10qkZj77k6vMhM02PBKUwt2iHj6sgtNt-F8e7EjD4hUNURIUFRZUTJHNVNKUTFNWVNNSzAxSDdFRS4u"
                    target="_blank"
                    rel="noreferrer"
                >
                    anonymous evaluation survey.
                </a>
            </p>

            <p>
                <strong className="text-emphasis">Helpful resources:</strong>
            </p>

            <ul>
                <li>
                    <a
                        href="https://www.ecehh.org/wp/wp-content/uploads/2021/09/LCAT-Introduction-to-Local-Climate-Adaptation-May-2024.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Introduction to Local Climate Adaptation.
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.ecehh.org/wp/wp-content/uploads/2021/09/LCAT-USER-GUIDE_FINAL-Feb-24.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        LCAT User Guide.
                    </a>
                </li>
            </ul>

            <p>
                <strong className="text-emphasis">Data disclaimer:</strong>
            </p>

            <p>
                With apologies to our users in Northern Ireland, unfortunately the dataset we are using to model local
                future climate does not cover Northern Ireland. We have identified an alternative dataset that will
                allow us to provide climate models for the region, this will be added as soon as possible.
            </p>
        </div>
    );
}

export default Introduction;
