import React, { useState } from "react";

const SvgLowLocalKnowledge = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116.629}
            height={116.629}
            viewBox="0 0 30.858 30.858"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...props}
        >
            <g transform="translate(0 -266.142)">
                <g
                    style={{
                        fill: "#fff",
                    }}
                >
                    <path
                        d="M274.8 274.8v18.8h-37.5v-18.8c0-10.3 8.4-18.8 18.8-18.8s18.7 8.4 18.7 18.8"
                        style={{
                            fill: "#fff",
                        }}
                        transform="translate(2.251 267.803)scale(.05168)"
                    />
                    <path
                        d="m256 68.5-93.7 65.6V68.5h-37.5v91.9l-56.2 39.4H106v243.7h300V199.8h37.5zm56.3 318.7H199.8c-10.4 0-18.8-8.4-18.8-18.8v-75h18.8v-15.7c0-30.5 22.9-57.7 53.3-59.3 32.5-1.6 59.2 24.1 59.2 56.2v18.8H331v75c0 10.5-8.4 18.8-18.7 18.8"
                        style={{
                            fill: "#fff",
                        }}
                        transform="translate(2.251 267.803)scale(.05168)"
                    />
                </g>
                <circle
                    cx={15.429}
                    cy={281.571}
                    r={15.429}
                    style={{
                        opacity: 1,
                        fill: "#031d44",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 0.5,
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeOpacity: 1,
                    }}
                />
                <g
                    style={{
                        strokeWidth: 7.57722664,
                    }}
                >
                    <g
                        style={{
                            strokeWidth: 53.97331238,
                        }}
                    >
                        <g
                            style={{
                                strokeWidth: 364.55151367,
                            }}
                        >
                            <g
                                style={{
                                    strokeWidth: 3274.87963867,
                                }}
                            >
                                <g
                                    style={{
                                        strokeWidth: 23946.93554688,
                                    }}
                                >
                                    <g
                                        style={{
                                            strokeWidth: 158580.03125,
                                        }}
                                    >
                                        <g
                                            style={{
                                                strokeWidth: 963794.125,
                                            }}
                                        >
                                            <g
                                                style={{
                                                    strokeWidth: 6409034.5,
                                                }}
                                            >
                                                <g
                                                    style={{
                                                        strokeWidth: 76475104,
                                                    }}
                                                >
                                                    <g
                                                        style={{
                                                            fill:
                                                                props.selectedVulnerability ===
                                                                "People living in area for a short time"
                                                                    ? "#FFD667"
                                                                    : isHovered
                                                                    ? "#f5821fff"
                                                                    : "#fff",
                                                            strokeWidth: 60164044,
                                                        }}
                                                    >
                                                        <path
                                                            d="M334.09 401.78v25.883l219.09-244.58h-6.988zm-11.03-5.67h-22.215v-10.805c4.965-2.387 8.387-7.465 8.352-13.34-.461-72.52-13.156-120.5-37.746-142.6-10.492-9.434-20.422-11.652-26.023-12.07a14 14 0 0 0-1.832-.129l-71.031-.004c-.621 0-1.227.055-1.825.129-5.601.414-15.535 2.629-26.03 12.07-24.587 22.105-37.286 70.086-37.747 142.6-.035 5.875 3.383 10.953 8.352 13.34v10.805l-22.215.008v131.45h56.07v-131.45h-22.215v-10.34c5.477-2.113 9.383-7.394 9.422-13.617.418-65.992 11.395-97.188 20.53-111.74.49-.777.97-1.5 1.45-2.191v279.04c0 12.555 10.18 22.727 22.727 22.727 12.555 0 22.73-10.172 22.73-22.727v-165.96h8.52v165.96c0 12.555 10.18 22.727 22.727 22.727 12.555 0 22.73-10.172 22.73-22.727v-279.08c9.835 14.023 21.543 45.148 21.977 113.97.039 6.226 3.945 11.508 9.421 13.62v10.34l-22.203-.003v131.45h56.07zm-73.62-230.79c0 22.844-18.52 41.363-41.363 41.363-22.848 0-41.367-18.52-41.367-41.363 0-22.848 18.52-41.367 41.367-41.367 22.844 0 41.363 18.52 41.363 41.367m289.27-54.71 12.211 64.051 12.211-64.051c25.043-5.574 43.766-27.906 43.766-54.629 0-30.914-25.059-55.977-55.977-55.977s-55.977 25.062-55.977 55.977c-.004 26.723 18.727 49.055 43.766 54.629m12.211-94.07c21.75 0 39.441 17.695 39.441 39.445S572.671 95.43 550.921 95.43s-39.445-17.695-39.445-39.445 17.699-39.445 39.445-39.445"
                                                            style={{
                                                                fill:
                                                                    props.selectedVulnerability ===
                                                                    "People living in area for a short time"
                                                                        ? "#FFD667"
                                                                        : isHovered
                                                                        ? "#f5821fff"
                                                                        : "#fff",
                                                                strokeWidth: 60164044,
                                                            }}
                                                            transform="translate(2.206 270.912)scale(.03758)"
                                                        />
                                                        <path
                                                            d="M565.57 55.977c0 8.086-6.555 14.645-14.641 14.645s-14.641-6.559-14.641-14.645 6.555-14.641 14.641-14.641 14.641 6.555 14.641 14.641"
                                                            style={{
                                                                fill:
                                                                    props.selectedVulnerability ===
                                                                    "People living in area for a short time"
                                                                        ? "#FFD667"
                                                                        : isHovered
                                                                        ? "#f5821fff"
                                                                        : "#fff",
                                                                strokeWidth: 60164044,
                                                            }}
                                                            transform="translate(2.206 270.912)scale(.03758)"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SvgLowLocalKnowledge;
