import * as React from "react";
const SvgDamageOrLossOfPossessionsOrHome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#266334",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M5.22 5.74h26.819v26.819H5.22z"
        style={{
          clipRule: "evenodd",
          fill: "none",
          fillRule: "evenodd",
          strokeWidth: 0.26458335,
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <g
        style={{
          strokeWidth: 19.83745193,
          fill: "#fff",
        }}
      >
        <path
          d="M380 241.66V140H280v30.82l83.922 83.918z"
          style={{
            strokeWidth: 19.83745193,
            fill: "#fff",
          }}
          transform="translate(4.7 4.575)scale(.01778)"
        />
        <path
          d="M1154 69.84 1130.18 46a20.835 20.835 0 0 0-28.758 0l-473.14 473.1a40.02 40.02 0 0 1-28.281 11.711A40.02 40.02 0 0 1 571.72 519.1L98.58 46a20.835 20.835 0 0 0-28.758 0l-23.82 23.82a20.33 20.33 0 0 0-5.965 14.379c0 5.395 2.149 10.57 5.965 14.379l473.08 473.14a40 40 0 0 1 11.715 28.281 40 40 0 0 1-11.715 28.281l-473.08 473.14a20.3 20.3 0 0 0-5.96 14.367c0 5.39 2.144 10.562 5.96 14.371l23.82 23.84a20.76 20.76 0 0 0 28.738 0l347.06-346.98.602-.602 40.18-40.199.62-.62 84.7-84.68-.004.003a40 40 0 0 1 28.28-11.715 40 40 0 0 1 28.282 11.715l85.32 85.32 40.18 40.16.62.621 347.02 346.98a20.75 20.75 0 0 0 14.368 5.778 20.76 20.76 0 0 0 14.37-5.778l23.84-23.82a20.3 20.3 0 0 0 0-28.738l-473.08-473.16a40 40 0 0 1-11.714-28.28 40 40 0 0 1 11.715-28.282l473.08-473.14a20.3 20.3 0 0 0 5.96-14.367c0-5.39-2.144-10.562-5.96-14.37z"
          style={{
            strokeWidth: 19.83745193,
            fill: "#fff",
          }}
          transform="translate(4.7 4.575)scale(.01778)"
        />
        <path
          d="M477.66 368.48 600 490.82l122.34-122.34L600 268.98ZM47.422 564a20.18 20.18 0 0 0-7.11 13.418A20.2 20.2 0 0 0 44.56 592l25.62 30.84a19.84 19.84 0 0 0 28 2.738l230.66-187.58-56.84-56.84z"
          style={{
            strokeWidth: 19.83745193,
            fill: "#fff",
          }}
          transform="translate(4.7 4.575)scale(.01778)"
        />
        <path
          d="M587.38 227.68a20 20 0 0 1 12.621-4.484c4.598 0 9.055 1.582 12.621 4.484L750.762 340l56.879-56.859-195.06-158.62a19.7 19.7 0 0 0-25.058 0l-195.16 158.72 56.879 56.762zM490.8 600 357.26 466.46 180 610.64V910.8Zm218.4 0L1020 910.82V610.64L842.74 466.46ZM420 1080a19.98 19.98 0 0 0 14.141-5.86 19.98 19.98 0 0 0 5.86-14.14V869.2L229.18 1080zm709.8-457.22 25.582-30.781a19.64 19.64 0 0 0 4.578-14.48 20 20 0 0 0-7.219-13.68l-224.78-182.68-56.84 56.84 230.46 187.46a19.875 19.875 0 0 0 28.22-2.68zM970.8 1080 760 869.2V1060a19.98 19.98 0 0 0 5.86 14.141 19.98 19.98 0 0 0 14.14 5.86zM600 709.2l-50.801 50.801h101.6z"
          style={{
            strokeWidth: 19.83745193,
            fill: "#fff",
          }}
          transform="translate(4.7 4.575)scale(.01778)"
        />
      </g>
    </g>
  </svg>
);
export default SvgDamageOrLossOfPossessionsOrHome;
