import * as React from "react";
const SvgDrowningOrFloodRelatedAccidents = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
      transform="translate(0 -266.142)"
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#351431",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <g
        style={{
          fill: "#fff",
          strokeWidth: 17.55135345,
        }}
      >
        <path
          d="M600 298.8c18 0 33.602-15.602 33.602-33.602V190.8c0-18-15.602-33.602-33.602-33.602S566.398 172.8 566.398 190.8v74.398c0 18 15.602 33.602 33.602 33.602m-159.6 18c6 10.801 18 16.801 28.801 16.801 6 0 12-1.2 16.801-4.8 15.602-9.602 21.602-30 12-45.603l-37.199-64.8c-9.602-15.603-30-21.603-45.602-12-15.602 9.6-21.602 30-12 45.601zm273.6 12c4.8 3.602 10.801 4.8 16.801 4.8 12 0 22.801-6 28.801-16.8l37.199-64.801c9.602-15.602 3.602-36-12-45.602s-36-3.602-45.602 12L702 283.197c-9.602 15.603-4.8 36 12 45.603m-112.75 39.44c68.926.61 124.31 56.977 123.7 125.9-.605 68.926-56.973 124.31-125.9 123.7-68.926-.605-124.31-56.973-123.7-125.9.61-68.926 56.977-124.31 125.9-123.7"
          style={{
            fill: "#fff",
            strokeWidth: 17.55135345,
          }}
          transform="translate(3.472 267.922)scale(.0201)"
        />
        <path
          d="M283.2 586.8c25.199 78 82.801 138 159.6 166.8 9.602 3.602 18 4.8 28.801 8.398v136.8c4.8 4.801 13.199 10.801 24 10.801s19.199-6 24-10.8c21.602-22.802 50.398-36 81.602-36 31.199 0 60 13.198 81.602 36 4.8 4.8 13.199 10.8 24 10.8 8.398 0 16.801-3.601 24-10.8l-.004-138c9.602-2.399 19.199-4.801 28.801-8.399 75.602-28.801 134.4-88.801 159.6-166.8l28.801-88.801c10.801-31.199-7.2-63.602-37.199-73.199-31.199-10.801-63.602 7.2-73.199 37.199l-28.801 90c-14.398 43.199-46.801 76.801-88.801 92.398-20.398 7.2-40.801 10.801-62.398 10.801h-110.4c-20.398 0-42-3.602-61.199-10.801-42-15.602-74.398-49.199-88.801-92.398l-28.801-90c-9.602-31.199-43.199-48-73.199-37.199-31.199 9.602-48 43.199-37.199 73.199zm843.6 369.6c-21.602 0-43.199-9.602-58.801-26.398-12-13.199-28.801-20.398-46.801-20.398-16.801 0-33.602 7.2-46.801 20.398-16.801 16.801-37.199 26.398-58.801 26.398s-43.199-9.602-58.801-26.398c-13.199-13.199-28.801-20.398-46.801-20.398-16.801 0-33.602 7.2-46.801 20.398-16.801 16.801-37.199 26.398-58.801 26.398s-43.199-9.602-58.801-26.398c-12-13.199-28.801-20.398-46.801-20.398-16.801 0-33.602 7.2-46.801 20.398-16.801 16.801-37.199 26.398-58.801 26.398s-43.199-9.602-58.801-26.398c-12-13.199-28.801-20.398-46.801-20.398-16.801 0-33.602 7.2-46.801 20.398-16.801 16.801-37.199 26.398-58.801 26.398s-43.199-9.602-58.801-26.398c-12-13.199-28.801-19.199-46.801-19.199-17.988 0-32.387 7.2-44.387 20.398-15.602 16.801-37.199 26.398-58.801 26.398-24 0-43.199 19.199-43.199 43.199s19.199 43.199 43.199 43.199c38.398 0 75.602-13.199 105.6-38.398 30 25.199 67.199 38.398 105.6 38.398 38.398 0 75.602-13.199 105.6-38.398 30 24 67.199 38.398 105.6 38.398 38.398 0 75.602-13.199 105.6-38.398 30 24 67.199 38.398 105.6 38.398 38.398 0 75.602-13.199 105.6-38.398 30 24 67.199 38.398 105.6 38.398 38.398 0 75.602-13.199 105.6-38.398 30 24 67.199 38.398 105.6 38.398 24 0 43.199-19.199 43.199-43.199-2.414-23.996-21.613-44.398-45.613-44.398z"
          style={{
            fill: "#fff",
            strokeWidth: 17.55135345,
          }}
          transform="translate(3.472 267.922)scale(.0201)"
        />
      </g>
    </g>
  </svg>
);
export default SvgDrowningOrFloodRelatedAccidents;
