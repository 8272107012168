import React, { useState } from "react";

const SvgUnderFives = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116.629}
            height={116.629}
            viewBox="0 0 30.858 30.858"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...props}
        >
            <g transform="translate(0 -266.142)">
                <g
                    style={{
                        fill: "#fff",
                    }}
                >
                    <path
                        d="M274.8 274.8v18.8h-37.5v-18.8c0-10.3 8.4-18.8 18.8-18.8s18.7 8.4 18.7 18.8"
                        style={{
                            fill: "#fff",
                        }}
                        transform="translate(2.251 267.803)scale(.05168)"
                    />
                    <path
                        d="m256 68.5-93.7 65.6V68.5h-37.5v91.9l-56.2 39.4H106v243.7h300V199.8h37.5zm56.3 318.7H199.8c-10.4 0-18.8-8.4-18.8-18.8v-75h18.8v-15.7c0-30.5 22.9-57.7 53.3-59.3 32.5-1.6 59.2 24.1 59.2 56.2v18.8H331v75c0 10.5-8.4 18.8-18.7 18.8"
                        style={{
                            fill: "#fff",
                        }}
                        transform="translate(2.251 267.803)scale(.05168)"
                    />
                </g>
                <circle
                    cx={15.429}
                    cy={281.571}
                    r={15.429}
                    style={{
                        opacity: 1,
                        fill: "#031d44",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 0.5,
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeOpacity: 1,
                    }}
                />
                <g
                    style={{
                        strokeWidth: 7.57722664,
                    }}
                >
                    <path
                        d="M433.152 6.969c15.68 17.359 24.078 41.44 19.602 66.078-5.601 30.8-32.48 52.64-62.719 52.64-3.36 0-7.28-.558-10.64-1.12-28.56-5.04-47.603-31.923-42.56-60.48 2.239-11.2 8.399-21.282 17.36-28 9.519-6.72 20.718-8.961 31.921-7.282 19.602 3.36 32.48 21.84 29.121 41.441-2.8 16.238-18.48 26.88-34.16 24.078-3.36-.558-5.601-3.922-5.039-7.28.559-3.36 3.922-5.603 7.281-5.04 9.52 1.68 18.48-4.48 20.16-14 2.239-12.879-6.16-24.641-19.039-26.879-7.84-1.68-16.238.559-22.96 5.04-6.72 4.48-11.2 11.761-12.32 19.601-3.923 21.84 10.64 42.56 32.48 45.922 28 5.04 54.32-14 59.358-42 4.48-27.44-8.398-53.762-30.8-67.199-7.282-.558-14.56-1.121-21.84-1.121-137.77 1.684-249.77 113.68-249.77 251.44s112 249.76 249.76 249.76 249.76-112 249.76-249.76c-.005-122.64-88.485-224.56-204.96-245.84zm-161.84 219.52c0-14.56 11.762-26.32 26.32-26.32 14.56 0 26.32 11.762 26.32 26.32s-11.762 26.32-26.32 26.32c-14.559-.004-26.32-11.762-26.32-26.32m216.72 122.64c-35.28 25.762-68.879 34.16-98.559 34.16-59.359 0-100.8-34.16-101.92-34.72-5.039-4.48-6.16-12.32-1.68-17.358 4.481-5.04 12.32-6.16 17.36-1.68 3.359 2.8 80.64 64.96 169.68-.559 5.601-3.922 13.44-2.8 17.359 2.801 4.48 5.598 3.359 13.438-2.239 17.355zm-5.601-96.32c-14.56 0-26.32-11.762-26.32-26.32 0-14.56 11.762-26.32 26.32-26.32s26.32 11.762 26.32 26.32c0 14.562-11.762 26.32-26.32 26.32"
                        style={{
                            fill:
                                props.selectedVulnerability === "Under 5s"
                                    ? "#FFD667"
                                    : isHovered
                                    ? "#f5821fff"
                                    : "#fff",
                            strokeWidth: 7.57722664,
                        }}
                        transform="translate(-2.649 269.447)scale(.04656)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default SvgUnderFives;
