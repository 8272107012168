import * as React from "react";
const SvgDamageToLocalEconomy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#266334",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M5.22 5.74h26.819v26.819H5.22z"
        style={{
          clipRule: "evenodd",
          fill: "none",
          fillRule: "evenodd",
          strokeWidth: 0.26458335,
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <g
        style={{
          strokeWidth: 18.18432045,
          fill: "#fff",
        }}
      >
        <path
          d="m178.12 360.94 112.5 91.312v544.5h-112.5zm287.26 91.68 69 67.875v476.25h-112.5v-513.19zM720.94 651l57.188-37.875v383.62h-112.5v-347.44a47.935 47.935 0 0 0 55.313 1.688zm243.18 139.69 57.75 10.688v195.38h-112.5v-309.19l36.188 33.938a39.735 39.735 0 0 0-6.05 53.75 39.75 39.75 0 0 0 24.612 15.438z"
          style={{
            strokeWidth: 18.18432045,
            fill: "#fff",
          }}
          transform="translate(3.888 3.689)scale(.0194)"
        />
        <path
          d="M1106 1071.7H94a18.75 18.75 0 0 1-18.75-18.75V147.09a18.752 18.752 0 0 1 28.125-16.238 18.75 18.75 0 0 1 9.375 16.238v887.11h993.27a18.748 18.748 0 0 1 16.238 28.125 18.75 18.75 0 0 1-16.238 9.375z"
          style={{
            strokeWidth: 18.18432045,
            fill: "#fff",
          }}
          transform="translate(3.888 3.689)scale(.0194)"
        />
        <path
          d="m1096.1 664.69-13.688 101.06a8.29 8.29 0 0 1-9.563 6.75c-62.57-11.5-39.359-7.227-101.81-18.75a2.144 2.144 0 0 1-.938-3.937l34.313-24.563c-116.45-109.56-74.383-70.004-185.06-174a10.33 10.33 0 0 0-13.312-1.687c-35.922 23.867-14.379 9.578-105.75 70.125a10.71 10.71 0 0 1-12.75-.75c-54.91-53.973-59.551-58.836-218.25-215.25-58.207 41.41-37.262 26.492-91.875 65.438a5.63 5.63 0 0 1-6.75-.187c-98.605-80.134-56.199-45.712-189.19-153.56a9.98 9.98 0 0 1-3.375-7.313v-93.188l.187-.187a1.747 1.747 0 0 1 2.813-1.313c134.15 105.89 87.848 69.367 203.44 160.5 46.938-33.492 26.355-18.824 91.312-65.062a5.94 5.94 0 0 1 7.312.563c39.164 38.59 162.65 160.26 214.88 211.88a10.446 10.446 0 0 0 12.75.937c80.406-53.305 57.2-37.914 105.75-70.125a10.33 10.33 0 0 1 13.312 1.688c236.51 235.95 148.09 147.92 231.56 231l31.312-22.312a2.179 2.179 0 0 1 3.375 2.25z"
          style={{
            strokeWidth: 18.18432045,
            fill: "#fff",
          }}
          transform="translate(3.888 3.689)scale(.0194)"
        />
      </g>
    </g>
  </svg>
);
export default SvgDamageToLocalEconomy;
