import * as React from "react";
const SvgNegativeImpactsOnAgriculturalAndLivestockProduction = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={15.429}
        cy={281.571}
        r={15.429}
        style={{
          opacity: 1,
          fill: "#266334",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M5.22 5.74h26.819v26.819H5.22z"
        style={{
          clipRule: "evenodd",
          fill: "none",
          fillRule: "evenodd",
          strokeWidth: 0.26458335,
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <path
        d="M14.705 20.796c-1.154-4.262-1.54-7.424-1.385-9.779l-.18.184c-.774.87-.507 1.267-.204 2.22.27.852-.39 1.865-.39 1.865s.507-.496-.14-1.498c-.585-.91-.827-2.678.944-3.149.053-.58.143-1.103.262-1.584 1.209-4.859 8.346-7.57 8.029-.681-.087 1.882-1.225 2.765-.238 4.667l.134.727c.394.117.826.394 1.292.86 1.337 1.338.496 3.126.496 3.126-1.114-.299-2.008-2.319-2.008-2.319.343 3.408-1.727 4.55-1.727 4.55-.923-2.258-.513-5.24.762-6.067l-.09-.486c-1.24-2.402.08-2.868.185-5.115.261-5.605-4.935-1.967-5.676 1.024-.34 1.36-.404 3.147-.096 5.5.559-.08 1.38.058 1.785 1.35.644 2.068.107 3.685.107 3.685-.226-2.28-.466-2.969-.75-3.418-.291-.47-.603-.7-1.054-.998.234 1.538.619 3.306 1.18 5.336h.59c.123 0 .238.038.334.102 1.626.909 5.948 1.681 8.2 1.12.767-.19 1.048.97.29 1.158a8.5 8.5 0 0 1-1.778.221c.09.173.242.355.506.507.678.39.085 1.422-.594 1.033-.844-.487-1.12-1.115-1.2-1.562a22 22 0 0 1-2.1-.248c-.295.58-.932 1.375-2.288 1.74-.76.202-1.068-.953-.308-1.156.779-.209 1.192-.55 1.408-.827-1.072-.255-2.01-.575-2.618-.892h-2.509c-.895.466-2.507.939-4.207 1.201.226.269.634.587 1.373.785.76.202.453 1.358-.307 1.155-1.397-.375-2.032-1.207-2.313-1.79-1.27.11-2.524.08-3.52-.167-.758-.187-.477-1.347.29-1.159 2.098.522 6.494-.132 8.24-1.14.025-.014.296-.08.296-.081z"
        style={{
          strokeWidth: 0.35277781,
          fill: "#fff",
        }}
      />
    </g>
  </svg>
);
export default SvgNegativeImpactsOnAgriculturalAndLivestockProduction;
