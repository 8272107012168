import React, { useState } from "react";

const SvgLowMobility = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116.629}
            height={116.629}
            viewBox="0 0 30.858 30.858"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...props}
        >
            <g transform="translate(0 -266.142)">
                <circle
                    cx={15.429}
                    cy={281.571}
                    r={15.429}
                    style={{
                        opacity: 1,
                        fill: "#031d44",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 0.5,
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeOpacity: 1,
                    }}
                />
                <g
                    style={{
                        strokeWidth: 7.57722664,
                    }}
                >
                    <g
                        style={{
                            strokeWidth: 53.97331238,
                        }}
                    >
                        <g
                            style={{
                                strokeWidth: 364.55151367,
                            }}
                        >
                            <g
                                style={{
                                    strokeWidth: 3274.87963867,
                                }}
                            >
                                <g
                                    style={{
                                        strokeWidth: 23946.93554688,
                                    }}
                                >
                                    <g
                                        style={{
                                            strokeWidth: 158580.03125,
                                        }}
                                    >
                                        <g
                                            style={{
                                                strokeWidth: 963794.125,
                                            }}
                                        >
                                            <g
                                                style={{
                                                    strokeWidth: 6409034.5,
                                                }}
                                            >
                                                <g
                                                    style={{
                                                        strokeWidth: 76475104,
                                                    }}
                                                >
                                                    <g
                                                        style={{
                                                            strokeWidth: 666309824,
                                                        }}
                                                    >
                                                        <path
                                                            d="M358.667-110.644c-26.798 0-48.715 21.893-48.715 48.677 0 26.785 21.917 48.695 48.715 48.695 26.797 0 48.694-21.913 48.694-48.695 0-26.784-21.896-48.677-48.694-48.677M328.002.143c-25.855 0-46.673 20.809-46.673 46.655v153.015l.163.33h94.53c29.931 0 54.62 24.6 54.62 54.544 0 10.557-3.122 20.41-8.407 28.794l1.842 1.058 38.78 14.05 45.231 88.585c18.592 30.273 63.36 3.172 45.868-28.301L498.757 247.21c-14.22-24.618-71.637-44.522-124.08-67.648V153.06c2.404 1.455 4.555 2.6 5.997 2.988l63.41 16.988c15.057 4.034 30.561-5.364 34.77-21.067 4.21-15.7-4.526-31.579-19.583-35.608l-60.326-16.166c-10.793-9.41-18.104-19.204-24.267-28.648v-24.75C374.678 20.949 353.86.14 328.005.14zm-148.246 78c-6.889-.48-14.036 1.062-20.454 4.755-17.112 9.852-23.05 31.957-13.162 49.04l72.362 153.25a9.34 9.34 0 0 0 8.46 5.376h149.057c19.746 0 35.953-16.154 35.953-35.883s-16.204-35.883-35.953-35.883H269.967L208.712 96.53a9 9 0 0 0-.271-.475c-6.18-10.674-17.175-17.112-28.682-17.912Zm353.304 54.799a41.7 41.7 0 0 0-16.408 2.788c-21.559 8.318-32.37 32.72-24.047 54.27a9.32 9.32 0 0 0 4.885 5.384 9.34 9.34 0 0 0 7.268.25 9.32 9.32 0 0 0 5.238-5.034 9.34 9.34 0 0 0 .043-7.268c-4.714-12.207 1.154-25.468 13.365-30.177 12.21-4.714 25.459 1.15 30.173 13.357l92.416 239.359a9.33 9.33 0 0 0 4.88 5.385 9.34 9.34 0 0 0 7.268.25 9.35 9.35 0 0 0 5.243-5.04 9.34 9.34 0 0 0 .037-7.267l-92.47-239.423c-6.243-16.166-21.535-26.29-37.884-26.827zM243.055 309.226c-.012 0-.024.012-.037.016-5.067.375-8.91 4.73-8.647 9.802.262 5.076 4.534 9.01 9.614 8.86h56.025v65.298h-22.609a9.333 9.333 0 0 0-9.127 9.331c0 5.072 4.055 9.215 9.127 9.327h63.885c5.072-.112 9.127-4.255 9.127-9.327a9.333 9.333 0 0 0-9.127-9.331h-22.608v-65.297h56.025a9.334 9.334 0 0 0 0-18.662H243.986a10 10 0 0 0-.93-.021z"
                                                            style={{
                                                                fill:
                                                                    props.selectedVulnerability ===
                                                                    "People with low personal mobility"
                                                                        ? "#FFD667"
                                                                        : isHovered
                                                                        ? "#f5821fff"
                                                                        : "#fff",
                                                                strokeWidth: 5805402624,
                                                            }}
                                                            transform="translate(-1.404 274.006)scale(.04049)"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SvgLowMobility;
