import React, { useState } from "react";

const SvgLowIncomes = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116.629}
            height={116.629}
            viewBox="0 0 30.858 30.858"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...props}
        >
            <g transform="translate(0 -266.142)">
                <g
                    style={{
                        fill: "#fff",
                    }}
                >
                    <path
                        d="M274.8 274.8v18.8h-37.5v-18.8c0-10.3 8.4-18.8 18.8-18.8s18.7 8.4 18.7 18.8"
                        style={{
                            fill: "#fff",
                        }}
                        transform="translate(2.251 267.803)scale(.05168)"
                    />
                    <path
                        d="m256 68.5-93.7 65.6V68.5h-37.5v91.9l-56.2 39.4H106v243.7h300V199.8h37.5zm56.3 318.7H199.8c-10.4 0-18.8-8.4-18.8-18.8v-75h18.8v-15.7c0-30.5 22.9-57.7 53.3-59.3 32.5-1.6 59.2 24.1 59.2 56.2v18.8H331v75c0 10.5-8.4 18.8-18.7 18.8"
                        style={{
                            fill: "#fff",
                        }}
                        transform="translate(2.251 267.803)scale(.05168)"
                    />
                </g>
                <circle
                    cx={15.429}
                    cy={281.571}
                    r={15.429}
                    style={{
                        opacity: 1,
                        fill: "#031d44",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 0.5,
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeOpacity: 1,
                    }}
                />
                <g
                    style={{
                        strokeWidth: 7.57722664,
                    }}
                >
                    <g
                        style={{
                            strokeWidth: 53.97331238,
                        }}
                    >
                        <g
                            style={{
                                strokeWidth: 364.55151367,
                            }}
                        >
                            <g
                                style={{
                                    strokeWidth: 3274.87963867,
                                }}
                            >
                                <g
                                    style={{
                                        strokeWidth: 23946.93554688,
                                    }}
                                >
                                    <g
                                        style={{
                                            strokeWidth: 158580.03125,
                                        }}
                                    >
                                        <g
                                            style={{
                                                strokeWidth: 963794.125,
                                            }}
                                        >
                                            <g
                                                style={{
                                                    fill: "#fff",
                                                    strokeWidth: 991505.25,
                                                }}
                                            >
                                                <path
                                                    d="M354.85 44.348c24.305-.012 42.922 18.418 42.953 42.527.031 23.531-19.371 42.703-43.102 42.578-23.129-.125-42.391-19.574-42.316-42.742.074-23.473 18.996-42.352 42.465-42.363M471.31 183.55c-9.98-6.352-19.906-12.793-29.965-19.027-11.07-6.875-22.199-13.672-33.434-20.305-1.398-.816-7.02-4.547-9.29-4.547H313.22c-2.117 0-10.004 3.567-14.07 6.074-20.348 12.555-40.555 25.336-60.73 38.172-6.016 3.82-9.543 9.637-7.336 16.7 7.39 23.757 15.238 47.362 23.07 70.968 2.406 7.266 8.356 13.738 15.469 10.66 18.512-8.015 20.863 17.137 28.66 23.961 4.742 4.157 5.938 8.657 5.918 14.566-.156 53.043-.094 106.09-.082 159.13 0 3.387-.074 6.813.313 10.16 1.132 9.801 8.355 17.31 18.64 19.7 8.32 1.91 19.067-1.9 23.239-9.774 2.707-5.102 4.449-11.375 4.48-17.137.328-52.57.191-78.996.191-131.57v-8.637h8.637v8.258c0 26.656.008 27.18 0 53.828-.012 27.137-.05 54.266-.039 81.395.012 10.57 5.121 17.98 14.609 22.199 9.574 4.262 17.949 1.383 25.316-5.312 5.863-5.332 6.992-12.426 6.992-19.855.012-54.984-.07-109.97.207-164.95.024-3.61 1.89-7.965 4.336-10.664 7.129-7.86 16.602-30.883 30.203-25.238 6.559 2.718 12.29-3.278 14.535-9.97 7.832-23.347 15.566-46.745 22.95-70.253 2.605-8.274-.114-13.891-7.419-18.54zm-167.65 99.023c-6.664-2.91-12.707-5.488-18.684-8.219-.723-.336-1.531-1.469-1.523-2.234.105-17.098-8.278-31.934-12.723-47.816-1.88-6.707-4.133-13.31-6.422-20.613 13.266-8.23 25.914-16.098 39.355-24.445-.004 34.648-.004 68.344-.004 103.33zm141.04-74.781c-4.96 16.152-10.309 32.18-15.227 48.348-1.375 4.523-1.566 9.39-2.57 14.043-.348 1.613-1.114 3.813-2.352 4.441-5.508 2.813-11.27 5.145-17.863 8.07v-103.43c13.043 8.048 25.105 15.364 36.961 23.036 1.113.71 1.55 3.86 1.05 5.496z"
                                                    style={{
                                                        fill: props.selectedVulnerability === "People on low incomes" ? "#FFD667" : isHovered ? "#f5821fff" : "#fff",
                                                        fillRule: "evenodd",
                                                        strokeWidth: 991505.25,
                                                    }}
                                                    transform="translate(-4.595 265.955)scale(.05642)"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SvgLowIncomes;
